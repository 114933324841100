<template>
    <div id="modal-apresentacaoCora"  class="modal modal-cora-apresentacao fade show"  style="display: block;">
        <div class="modal-dialog modal-apresentacao-cora" role="document">
            <div class="modal-content">
                <div class="header-modal-cora"> 
                    <b-navbar class="navbar header-modal">
                        <img :src="coraNovo" class="logo-cliente-sesi-modal ml-1" alt="Logo" >
                        <div class="d-flex flex-column">
                            <img :src="logoCora" class="logo-header-modal" alt="Logo" >
                            <span class="subtitulo-modal">Assistente virtual do SESI</span>
                        </div>
                        <button type="button" class="ml-auto d-flex align-items-center mb-3 fechar-modal" @click="fecharModal">
                            <img :src="fecharModalCora" class="" alt="fechar" >
                        </button>
                    </b-navbar>        
                </div>
                <div class="modal-body">
                    <div  class="container-modal p-2"> 
                       <span class="titulo_modal_apresentacao_cora"> Oi! Eu sou a Cora! 😄</span>
                       <div class="mt-2">
                            <p class="texto_modal_apresentacao_cora">
                                Sou assistente virtual do SESI de Santa Catarina. Fui criada em 2020 para realizar o monitoramento diário dos sintomas da COVID-19 😷 e, com o passar do tempo, fui ganhando novas habilidades.<br v-if="mobile"><br>
                                Hoje falo sobre diversos temas, incluíndo saúde mental 🧠, sou uma ferramenta de apoio para trazer os serviços de saúde do SESI para mais perto de você.<br v-if="mobile"><br>
                                Independente do momento, sempre vou querer saber sobre você e sua saúde. Um abraço da Cora!🤗
                            </p>
                       </div>
                    </div>
                    <div class="d-flex mt-2">
                        <a href="#" class="botao-voltar" @click="fecharModal">Voltar para o Chat</a>
                        <a href="https://www.cists.com.br/"  target="_blank" class="botao-modal-centro-inovacao ml-1" >Centro de Inovação SESI</a>
                        <a href="mailto:cis.contato@sesisc.org.br" class="botao-modal-fale-conosco ml-1" >Fale conosco</a>
                    </div>
                </div>
               
            </div>
        </div>
    </div> 
</template>

<script>
import { BNavbar, BContainer,BCol, BRow,BDropdown,BDropdownItem,BNavItemDropdown, BModal} from 'bootstrap-vue'
import coraNovo from '@/assets/images/cora/cora_new.png'
import logoCora from '@/assets/images/cora/logo_cora.svg'
import fecharModalCora from '@/assets/images/cora/close_modal.svg'

export default{
    components: {
        BNavbar,
        BContainer,
        BCol,
        BRow,
        BDropdown,
        BDropdownItem,
        BNavItemDropdown,
        BModal,
        coraNovo,
        fecharModalCora,
        logoCora
    },

    data() {
        return {
            coraNovo: coraNovo,
            logoCora: logoCora,
            fecharModalCora: fecharModalCora,
            mobile: true,
        }
    },

    mounted(){
        this.verificaLargua()
        window.addEventListener('resize', () => {
            this.verificaLargua()
        })
    },

    methods: {
        fecharModal(){
            this.$emit('fecharModalApresentacaoCora')
        },

        verificaLargua(){
            if(window.innerWidth <= 550){
                this.mobile = false
            }else{
                this.mobile = true
            }
        }
    }
}
</script>

<style scoped>
.close{
    color: #fff !important; 
}
/* .modal-apresentacao-cora{
    top: 30px;
} */
#modal-apresentacaoCora{
    overflow: auto;
}
.modal-dialog{
    width: 38%;
    max-width:none !important;
    min-width:566px !important;
}
.fechar-modal{
    background-color: transparent;
    border: none;
}
.header-modal{
    height: 15%;
    background: rgb(36,109,186);
    background: linear-gradient(150deg, rgba(36,109,186,1) 0%, rgba(4,42,108,1) 60%, rgba(85,44,145,1) 100%);
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: center;
    color: #fff;
}
.logo-cliente-sesi-modal{
    width: 94.86px;
    height: 94.86px;
    margin: 3%;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-header-modal {
    width: 205px;
    height: 49px;
}
.subtitulo-modal {
    font-size: 12.7684px;
    font-weight: 400;
    margin-top: 10px !important;
    font-style: normal;
    line-height: 16px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
}

/* Content */
.modal-body {
  background-color: #EDEDED;
  padding: 40px;
  border-radius: 6px;
}

.container-modal{
    height: 395px;
    background-color: #fff;
    width: 100%;
    border-radius: 5px;
    margin: 0 auto;
    box-shadow: 2px 4px 4px 0px #2772C01A;
}

.titulo_modal_apresentacao_cora{
    font-family: 'Montserrat',serif;
    font-size: 19.44px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;

}
.texto_modal_apresentacao_cora{
    font-family: 'Montserrat',serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 29.16px;
    text-align: left;

}

.botao-voltar{
    width: 155px;
    height: 40px;
    color: #FFFFFF;
    padding-top: 10px;
    border-radius: 20px;
    border: none;
    font-family: 'Montserrat',serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    background-color: #002060;
}
.botao-modal-centro-inovacao{
    width: 206px;
    height: 40px;
    background-color: #FFFFFF;
    padding-top: 10px;
    color: #4B4B4B;
    border: none;
    border-radius: 20px;
    font-family: 'Montserrat',serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;

}

.botao-modal-fale-conosco{
    width: 126px;
    height: 40px;
    background-color: #FFFFFF;
    padding-top: 10px;
    color: #4B4B4B;
    border: none;
    border-radius: 20px;
    font-family: 'Montserrat',serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
}

/* Responsividade */
@media (max-width: 1700px) {
    .container-modal{
        height: 415px;
    }
}

@media (max-width: 768px) {
    .modal-apresentacao-cora{
         top: 70px;
    }

    .modal-dialog{
        width: 98%;
        max-width:none !important;
        min-width:0 !important;
    }
}




@media (max-width: 568px) {
    .modal-body{
        background-color: #EDEDED;
        padding: 10px;
        border-radius: 6px;
        height: 540px;
    }
    .modal-apresentacao-cora{
         top: 20px;
    }
    .container-modal{
        height: 420px;
    }
    #modal-apresentacaoCora{
        width: 100%;
        margin-left: 0;
    }
    .modal-cora-apresentacao{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .botao-modal-fale-conosco{
        height: 50px;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
    .botao-modal-centro-inovacao{
        height: 50px;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
    .botao-voltar{
        height: 50px;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
    .modal-dialog{
        width: 100%;
        margin: 0 !important;
    }
}   



@media (max-width: 500px) {
    .container-modal{
        height: 395px;
    }
    .modal-dialog{
        width: 100%;
        margin: 0 !important;
    }
    .logo-header-modal{
        width: 130px;
        height: 37px;
    }
    .modal-body{
        height: 500px;
    }
    .modal-apresentacao-cora{
         top: 0px;
    }
    .texto_modal_apresentacao_cora{
        font-size: 12px;
        line-height: 25px;
    }
    .botao-modal-fale-conosco{
        height: 60px;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
    .botao-modal-centro-inovacao{
        height: 60px;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
    .botao-voltar{
        height: 60px;
        padding-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
 
}   

</style>